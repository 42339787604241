<template>
  <div class="width-100 flex-row justify-content-center align-items-center" :style="bgColor == 'white' ? 'background:white' : 'background:#F3F9FF'">
    <div class="container-box">
      <div class="width-100 footer flex-row justify-content-spaceBetween align-items-center">
        <div class="width-85">
          <img class="margin-b height-logo" src="../assets/image/logo.png" alt="" />
          <p class="fs-sml darkgrey line-height">
            中共瑞安市委宣传部（瑞安市新闻出版局）©版权所有<br /><a class="fs-sml darkgrey" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023051922号-2</a>&nbsp;&nbsp;&nbsp;技术支持：瑞安市数据管理发展有限公司<br />如有问题咨询服务热线：0577-65812717
          </p>
        </div>
        <div class="width-15 textAlign-c">
          <img src="../assets/image/ewm.png" width="50%" alt="" />
          <p class="black line-height2 margin-ts">浙里办扫码</p>
          <p class="fs-sml darkgrey">瑞安版权直通车</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'index',
  components: {},
  props: {
    bgColor: {
      type: String,
      default: 'white'
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.footer {
  padding: 40px 0;
}
</style>
